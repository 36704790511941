require('./bootstrap');
require('./owl/owl.carousel')

$(document).ready(function(){
    $(".owl-carousel").owlCarousel({
        center: true,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true
    });
});